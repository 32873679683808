<template>
	<div>
		<div class="wrapper">
			<div class="mall-content">
				<div class="mall-left">
					<div class="cate-box">
						<el-skeleton :loading="cate_loading" animated :count="10">
							<el-tabs v-model="activeCate" tab-position="left">
								<el-tab-pane :label="item.title" :name="`${item.id}`" v-for="(item, index) in cates" :key="index">
									<ul class="cate-list">
										<li class="cate-item" v-for="(c, i) in item.children" :key="i" @click="onCateClick(c.id)">
											<el-image class="cate-image" :src="c.image"></el-image>
											<span class="cate-title">{{ c.title }}</span>
										</li>
									</ul>
								</el-tab-pane>
							</el-tabs>
						</el-skeleton>
					</div>
				</div>
				<div class="mall-right">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane :label="item.title" :name="item.value" v-for="(item, index) in platforms" :key="index">
							<ul>
								<el-skeleton :loading="item.loading" animated :count="10">
									<li class="goods-item" v-for="(goods, index) in item.data" :key="index">
										<goods-item :goods="goods"></goods-item>
									</li>

									<el-empty description="暂无更多数据" v-if="item.data.length <= 0"></el-empty>
								</el-skeleton>
							</ul>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import { Tabs, TabPane, Skeleton, SkeletonItem, Card, Carousel, CarouselItem, Image, Empty } from 'element-ui';
import GoodsItem from '@/views/pc/components/GoodsItem'
import SimpleGoods from '@/views/pc/components/SimpleGoods'

Vue.use(Tabs).use(TabPane).use(Image).use(Skeleton).use(SkeletonItem).use(Card).use(Carousel).use(CarouselItem).use(Empty)

import { getRefinement } from '@/api/pc'
import { getCategory } from "@/api/shop";
import { searchByCate } from '@/api/search'

export default {
	name: "SuperMall",
	components: {
		GoodsItem, SimpleGoods
	},
	data () {
		return {
			activeName: 'jd',
			activeCate: '16',
			click_cate: null,
			cate_loading: true,
			platforms: {
				jd: {
					title: "京 东",
					loading: true,
					value: 'jd',
					page: 1,
					data: []
				},
				tb: {
					title: "淘 宝",
					loading: true,
					value: 'tb',
					page: 1,
					data: []
				},
				pdd: {
					title: "拼多多",
					loading: true,
					value: 'pdd',
					page: 1,
					data: []
				},
				vip: {
					title: "唯品会",
					loading: true,
					value: 'vip',
					page: 1,
					data: []
				}
			},
			cates: [],
			banners: [
				"http://img.taojiefang.cn/FqgScSyB2x3rFc9hIE_5rUP-yM5a",
				"http://img.taojiefang.cn/FlbGFKJjn-lzs501UQdyUDEqWMk0",
				"http://img.taojiefang.cn/Fi-IvDF3Ni5fSEi33eC_uYuyDVGr",
				"http://img.taojiefang.cn/FkvYZv3vvb4QwojlFTX2ciNi2Vpj"
			]
		}
	},
	computed: {},
	created () {},
	mounted () {
		this._loadCates()
		this.click_cate = this.activeCate
		this._loadGoods(this.activeCate)
	},
	methods: {
		handleClick(val) {
			this._loadGoods(this.click_cate)
		},
		onCateClick(cate_id) {
			this.click_cate = cate_id
			this._loadGoods(cate_id)
		},
		_loadCates() {
			getCategory().then((res)=>{
				this.cate_loading = false
        if(res.errCode==0){
          this.cates = res.data
        }
      })
		},
		_loadGoods(cate_id) {
			let params = {
				platform: this.activeName,
				cate_id: cate_id,
				rows: 30
			}
			this.platforms[this.activeName].loading = true
			searchByCate(params).then(res => {
				if(res.errCode === 0) {
					this.platforms[this.activeName].loading = false
					this.platforms[this.activeName].data = res.data
				}
			})
		}
	}
}
</script>

<style scoped>
.mall-content {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
}

.mall-left {
	box-sizing: border-box;
	width: 390px;
	border: 1px solid #f8f8f8;
	border-radius: 2px;
}

.mall-right {
	box-sizing: border-box;
	padding: 10px;
	width: 600px;
	min-height: 600px;
	background: #fff;
}

.goods-item {
	border-bottom: 1px solid #f8f8f8;
}
.goods-item:last-child {
	border-bottom: none;
}
.box-card {
	margin-bottom: 10px;
}

.cate-box {
	padding: 10px 5px;
	background: #fff;
}
.cate-list {
	display: flex;
	flex-wrap: wrap;
}
.cate-item {
	box-sizing: border-box;
	width: 100px;
	padding: 10px;
	height: 120px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	cursor: pointer;
}
.cate-image {
	width: 70px;
	height: 70px;
}
.cate-title {
	font-size: 13px;
	color: #555;
}
</style>
